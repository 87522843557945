import { PermissionExpression, useIdentity } from 'SRC/hooks/useIdentity';
import { FC, ReactElement } from 'react';
import { PageWrapper } from 'LEGACY/layout/PageLayout';
import GridWidthContainer from 'LEGACY/layout/GridWidthContainer';
import { Space } from 'LEGACY/layout';
import { EmptyStateUnauthorized } from 'LEGACY/components/EmptyState';
import { Navigate, useLocation } from 'react-router-dom';
import { pathTo } from 'SRC/components/RoutesMain/routing';

export const UnauthorizedPage: FC<React.PropsWithChildren<unknown>> = () => (
  <PageWrapper>
    <GridWidthContainer>
      <Space of='col48' />
      <EmptyStateUnauthorized />
    </GridWidthContainer>
  </PageWrapper>
);

export interface IsAuthorizedProps {
  for?: PermissionExpression;
  not?: boolean;
  children: ReactElement;
}
export const LoggedAndAuthorized: FC<
  React.PropsWithChildren<IsAuthorizedProps>
> = ({ children, for: forProp, not }) => {
  const { loggedIn } = useIdentity();
  const { canDo } = useIdentity();
  const location = useLocation();

  if (!loggedIn) {
    return <Navigate to={pathTo.login} state={{ from: location }} replace />;
  }

  const authorized = forProp ? (not ? !canDo(forProp) : canDo(forProp)) : true;

  if (authorized) return children;

  return <UnauthorizedPage />;
};

export const NotLogged: FC<
  React.PropsWithChildren<{ children: ReactElement }>
> = ({ children }) => {
  const { loggedIn } = useIdentity();

  if (!loggedIn) {
    return children;
  }

  return <Navigate to={pathTo.root} replace />;
};
