import { parse, stringify } from 'query-string';
import { FC, ReactNode } from 'react';
import { ReactQueryConfig, ReactQueryConfigProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ContactSupport } from 'SRC/components/ContactSupport';
import { ErrorBoundary } from 'SRC/components/ErrorBoundary/ErrorBoundary';
import { RoutesMain } from 'SRC/components/RoutesMain/RoutesMain';
import { refetchOnWindowFocus } from 'SRC/config';
import { sentryErrorCapture } from 'SRC/fetch/sentryErrorCapture';
import { ConnectionInfoFetcher } from 'SRC/hooks/useConnectionsInfo';
import { IdentityProvider } from 'SRC/hooks/useIdentity';
import { SWRConfig } from 'swr';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { ToastProvider } from './components/Toast';

const ReactQueryConfigProviderWithTypes = ReactQueryConfigProvider as FC<
  React.PropsWithChildren<{
    config: ReactQueryConfig;
    children: ReactNode;
  }>
>;

const queryConfig = {
  queries: {
    retry: false,
    refetchOnWindowFocus,
  },
};
export default function App() {
  return (
    <ErrorBoundary>
      <SWRConfig
        value={{
          errorRetryCount: 10,
          onError: sentryErrorCapture(),
          onErrorRetry: (error, _key, _config, revalidate, { retryCount }) => {
            // Never retry on 4xx errors.
            if (error.status >= 400 && error.status < 500) return;
            // Only retry up to 5 times.
            if (retryCount >= 5) return;
            // Retry after 5 seconds.
            setTimeout(() => revalidate({ retryCount }), 5000);
          },
        }}
      >
        <ReactQueryConfigProviderWithTypes config={queryConfig}>
          <BrowserRouter>
            <ToastProvider>
              <IdentityProvider>
                <QueryParamProvider
                  adapter={ReactRouter6Adapter}
                  options={{
                    searchStringToObject: parse,
                    objectToSearchString: stringify,
                  }}
                >
                  <ErrorBoundary>
                    <ConnectionInfoFetcher>
                      <RoutesMain />
                      <ContactSupport />
                    </ConnectionInfoFetcher>
                  </ErrorBoundary>
                </QueryParamProvider>
              </IdentityProvider>
            </ToastProvider>
          </BrowserRouter>
        </ReactQueryConfigProviderWithTypes>
      </SWRConfig>
    </ErrorBoundary>
  );
}
