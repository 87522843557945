export const createEscapedRegExp = (str: string, flags?: string): RegExp => {
  const escaped = str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  return new RegExp(escaped, flags);
};

export function unescapeBasic(text: unknown) {
  if (typeof text !== 'string') return text;
  if (text.toLowerCase() === 'null') return null;
  return text.replace(/\\n/gi, '');
}
export const truncateString = (str: string | undefined, maxLength: number) => {
  if (!str) {
    return '';
  }
  if (str.length <= maxLength) {
    return str;
  }
  return `${str.slice(0, maxLength)}...`;
};

export const shortenMiddle = (
  str: string | undefined,
  maxLength: number,
  delimiter = '...',
) => {
  if (!str) {
    return '';
  }

  if (str.length <= maxLength) {
    return str;
  }

  const firstPartEndIndex = Math.floor(maxLength / 2);
  const firstPart = str.substring(0, firstPartEndIndex);

  // Do Max ceil since if maxLength is odd, we give one more char to the ending
  // then we make space for the delimiter in the ending as well
  const secondPartStartIndex =
    str.length - Math.ceil(maxLength / 2) + delimiter.length;
  const secondPart = str.substring(secondPartStartIndex);

  return `${firstPart}${delimiter}${secondPart}`;
};
