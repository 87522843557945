import { Link } from '@primer-io/goat';
import { FC } from 'react';
import { contactStrings } from 'SRC/components/ContactSupport/strings';

export const ContactSupportLink: FC = () => {
  return (
    <Link href={contactStrings.linkUrl} target='_blank'>
      {contactStrings.link}
    </Link>
  );
};
