import { useMemo, SetStateAction, useCallback } from 'react';
import * as React from 'react';
import Theme from 'LEGACY/theme';

import styled from 'styled-components';

import Text from 'LEGACY/typography/Text';
import { Space } from 'LEGACY/layout';
import Icon from 'LEGACY/components/Icon';

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  justify-content: center;

  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  margin: 0 10px;
`;

const Divider = styled.div`
  border-right: 1px solid ${Theme.colorPlaceholder};
`;

interface PaginationProps {
  currentPage: number;
  itemsPerPage: number;
  totalItemCount: number;
  setCurrentPage: React.Dispatch<SetStateAction<number>>;
}

const Pagination: React.FC<React.PropsWithChildren<PaginationProps>> = ({
  currentPage,
  itemsPerPage,
  totalItemCount,
  setCurrentPage,
}) => {
  const firstListItem = useMemo(
    () => currentPage * itemsPerPage,
    [currentPage, itemsPerPage],
  );
  const lastListItem = useMemo(
    () => Math.min(firstListItem + itemsPerPage, totalItemCount),
    [firstListItem, totalItemCount, itemsPerPage],
  );
  const totalPages = useMemo(
    () => Math.ceil(totalItemCount / itemsPerPage),
    [totalItemCount, itemsPerPage],
  );

  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage === totalPages - 1;

  const handleNextPage = useCallback(() => {
    if (isLastPage) return;

    setCurrentPage(currentPage + 1);
  }, [currentPage, setCurrentPage, isLastPage]);

  const handlePrevPage = useCallback(() => {
    if (isFirstPage) return;

    setCurrentPage(currentPage - 1);
  }, [currentPage, setCurrentPage, isFirstPage]);

  return (
    <PaginationContainer>
      <Text sentiment='regular'>{`Showing ${Math.max(
        firstListItem,
        1,
      )} to ${lastListItem} of ${totalItemCount} results`}</Text>
      <ButtonsContainer>
        <Button onClick={handlePrevPage} disabled={isFirstPage}>
          <IconContainer>
            <Icon
              name='ArrowLeft'
              sentiment={isFirstPage ? 'placeholder' : 'interactive'}
            />
          </IconContainer>
          <Text sentiment={isFirstPage ? 'placeholder' : 'interactive'}>
            Previous
          </Text>
        </Button>

        <Space of='row8' />
        <Divider>
          <Space of='col16' />
        </Divider>
        <Space of='row8' />

        <Button onClick={handleNextPage} disabled={isLastPage}>
          <Text sentiment={isLastPage ? 'placeholder' : 'interactive'}>
            Next
          </Text>
          <IconContainer>
            <Icon
              name='ArrowRight'
              sentiment={isLastPage ? 'placeholder' : 'interactive'}
            />
          </IconContainer>
        </Button>
      </ButtonsContainer>
    </PaginationContainer>
  );
};

export default Pagination;
