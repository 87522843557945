import { cloneElement, isValidElement, useRef } from 'react';
import { useOverflowDetector } from 'LEGACY/OverflowDetector';
import useHover from '@react-hook/hover';
import mergeRefs from 'react-merge-refs';
import Theme from 'LEGACY/theme';
import { Tooltip } from '@primer-io/goat';

type TextTooltipProps = { children: React.ReactElement | null } & {
  title: string;
  disabled?: boolean;
  zIndex?: number;
};

const TextTooltip = ({
  children,
  zIndex = Theme.zIndexOverDropdownPopout,
  disabled,
  ...otherProps
}: TextTooltipProps) => {
  const hoverRef = useRef(null);
  const isHovering = useHover(hoverRef, { enterDelay: 0, leaveDelay: 0 });

  const { ref: overflowRef, overflowing } = useOverflowDetector('text');

  const mergedRef = mergeRefs([hoverRef, overflowRef]);

  if (disabled) {
    return (
      <>
        {isValidElement(children)
          ? cloneElement<any>(children, { ref: mergedRef })
          : children}
      </>
    );
  }

  return (
    <Tooltip open={overflowing && isHovering}>
      <Tooltip.Trigger>
        {isValidElement(children)
          ? cloneElement<any>(children, { ref: mergedRef })
          : children}
      </Tooltip.Trigger>
      <Tooltip.Content
        side='top'
        style={{
          zIndex: zIndex,
        }}
      >
        {otherProps.title}
      </Tooltip.Content>
    </Tooltip>
  );
};

export default TextTooltip;
